<template>
  <div style="position:relative;width: calc(100vw);height: calc(100vh - 50px);overflow-y: scroll;">
    <div v-if="tempShow" style="line-height: 46px;padding: 2px 16px;background:#ecf9ff;color: #1989fa; ">
      <van-icon name="warn-o" color="#1989fa" />
      <span>{{tempTime}}</span>
    </div>
    <van-form @submit="onSubmit" @failed="failed" :label-width="labelWidth" ref="checkForm">
      <div v-if="Object.keys(groupForm).length>0">
        <div v-for="(items,k,i) in groupForm" v-bind:key="'form'+i">
          <div class="form-title" style="position:relative;padding-right: 40px;">
            {{k}}

          </div>

          <div v-for="(obj,key) in items" v-bind:key="'items'+ key">
            <div>
              <div v-if="obj.type=='radio1'" class="item">
                <span class="label-tips">*</span>
                <div class="item-label">{{key+1}}.{{obj.name}}</div>
                <van-field
                    :label-width="labelW"
                    :name="obj.id"
                    :label="''"
                    :placeholder="obj.name"
                    :rules="[{ required: true, message: '必填'}]"
                >
                  <template #input>
                    <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal">
                      <van-radio icon-size="24px" v-for='(o,i) in options[obj.id]' v-bind:key="i"  :name="needPics.includes(o)?'0':o" >
                        {{o}}
                      </van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <div v-show="formData[obj.id]['value']==='0'">
                  <van-field name="remark" rows="3" autosize
                             :rules="[{ validator, message: '',ruleId:obj.id  }]"
                             type="textarea" v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label=""
                             placeholder="检查出的问题及整改要求">
                    <span class="label-tips">*</span>
                  </van-field>
                  <div class="form-label"><span style="padding-right: 5px;color: red;position:relative;top: 1px;font-size: 0.8rem;">*</span><span></span>拍照上传</div>
                  <div class="form-image">
                    <van-field name="uploader" :rules="[{ validator:validatorPic, message: '至少上传一张照片', ruleId:obj.id  }]">

                      <template #input>
                        <van-uploader :name="'fileList-'+obj.id" @delete="del"  v-model="fileList[obj.id]"  capture="camera" :before-read="beforeRead" :after-read="afterRead" :max-count="6" />
                      </template>
                    </van-field>
                  </div>

                </div>
              </div>

              <div v-if="obj.type=='checkbox'" class="item">
                <span class="label-tips">*</span>
                <div class="item-label">{{key+1}}.{{obj.name}}</div>

                <van-field :label-width="labelW"
                           :name="obj.id"
                           :placeholder="obj.name"
                           :rules="[{ required: true, message: '必填'}]"
                >
                  <template #input>
                    <van-checkbox-group direction="horizontal" v-model="formData[obj.id]['value']" @change="changeCheck(obj.id)">
                      <van-checkbox shape="square" v-for='(o,i) in options[obj.id]' v-bind:key="i"  :name="o.trim()">{{o}}</van-checkbox>
                    </van-checkbox-group>
                  </template>
                </van-field>

                <div v-show="!(formData[obj.id]['value']&&formData[obj.id]['value'].length==1&&formData[obj.id]['value'][0].trim()=='没有问题')">
                  <van-field name="remark" rows="3" autosize
                             :rules="[{ validator1, message: '',ruleId:obj.id  }]"
                             type="textarea" v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label=""
                             placeholder="检查出的问题及整改要求">
                    <span class="label-tips">*</span>
                  </van-field>
                  <div class="form-label"><span style="padding-right: 5px;color: red;position:relative;top: 1px;font-size: 0.8rem;">*</span><span></span>拍照上传</div>
                  <div class="form-image">
                    <van-field name="uploader" :rules="[{ validator:validatorPic1, message: '至少上传一张照片', ruleId:obj.id  }]">
                      <template #input>
                        <van-uploader :name="'fileList-'+obj.id" @delete="del"  v-model="fileList[obj.id]"  capture="camera" :before-read="beforeRead" :after-read="afterRead" :max-count="6" />
                      </template>
                    </van-field>
                  </div>

                </div>
              </div>

              <div v-if="obj.type=='radio'" class="item">
                <span class="label-tips">*</span>
                <div class="item-label">{{key+1}}.{{obj.name}}</div>
                <van-field
                        :name="obj.id"
                        :label-width="labelW"
                        input-align="left"
                        :label="''"
                        :placeholder="obj.name"
                        :rules="[{ required: true, message: '必填'}]"
                >
                  <template #input>
                    <van-radio-group v-model="formData[obj.id]['value']" direction="horizontal">
                      <van-radio name="1" icon-size="24px">是</van-radio>
                      <van-radio name="0" icon-size="24px">否</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <div v-show="formData[obj.id]['value']==='0'">
                  <van-field name="remark" rows="3" autosize
                             :rules="[{ validator, message: '',ruleId:obj.id  }]"
                             type="textarea" v-model="formData[obj.id]['des']" maxlength="200" show-word-limit label=""
                             placeholder="检查出的问题及整改要求">
                    <span class="label-tips">*</span>
                  </van-field>
                  <div class="form-label"><span style="padding-right: 5px;color: red;position:relative;top: 1px;font-size: 0.8rem;">*</span><span></span>拍照上传</div>
                  <div class="form-image">
                    <van-field name="uploader" :rules="[{ validator:validatorPic, message: '至少上传一张照片', ruleId:obj.id  }]">

                      <template #input>
                        <van-uploader :name="'fileList-'+obj.id" @delete="del"  v-model="fileList[obj.id]"  capture="camera" :before-read="beforeRead" :after-read="afterRead" :max-count="6" />
                      </template>
                    </van-field>
                  </div>

                </div>
              </div>
              <div v-else-if="obj.type=='textarea'"  class="item">
                <span class="label-tips">*</span>
                <div class="item-label">{{key+1}}.{{obj.name}}</div>
                <van-field
                    v-model="formData[obj.id]['value']"
                    :name="obj.id"
                    :label-width="labelW"
                    input-align="left"
                    :label="'.'"
                    :placeholder="obj.name"
                    :rules="[{ required: false, message: '必填'}]"
                />
              </div>
            </div>

          </div>

        </div>

      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>处理意见</div>
      <van-field
          name="check"
          type="textarea"
          v-model="formData.check.des"
          autosize
          placeholder="请输入处理意见"
      />

      <div class="form-label" style="padding-bottom: 5px;"><span></span>企业签名</div>
      <div>
        <div style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" @click="openSign('checkedSign')">
          <div v-if="checkedSigned">
            <img :src="venueData.checkedSign" height="80px">
          </div>
        </div>
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人签名</div>
      <div style="border: 1px solid #ebedf0;height: 80px;margin: 8px 16px;border-radius: 4px;" @click="openSign('checkerSign')">
        <div v-if="checkerSigned">
          <img :src="venueData.checkerSign" height="80px">
        </div>
      </div>
      <div class="form-label" style="padding-bottom: 5px;"><span></span>检查人</div>
      <van-field
          name="check"
          v-model="venueData.remark"
          label=""
          placeholder="检查人"
      />
      <div class="form-label"><span style="padding-right: 5px;color: red;position:relative;top: 1px;font-size: 0.8rem;">*</span><span></span>现场照片</div>
      <div class="form-image">
        <van-field name="uploader">
          <template #input>
            <van-uploader v-model="pics"  capture="camera" :before-read="beforeRead" :after-read="sendFile" :max-count="6" />
          </template>
        </van-field>
      </div>
      <div style="height: 60px;"></div>
      <div style="margin-bottom:10px;width: calc(100vw);">
        <van-row  type="flex" justify="center"  gutter="0">

          <van-col span="12" v-if="venue.checkType!='1'">
            <div class="temp-save-btn" @click="tempSave">暂存</div>
<!--            <van-button  size="large" type="warning" @click="tempSave">暂存</van-button>-->
          </van-col>
          <van-col :span="venue.checkType!='1'?12:24">
            <van-button size="large" type="primary" :loading="loading" native-type="submit">提交</van-button>
          </van-col>
        </van-row>
<!--        <van-uploader v-model="fileTest"  :before-read="beforeRead" :after-read="afterRead" :max-count="6" />-->

      </div>
    </van-form>
    <van-dialog v-model="show" title="确认提交？" show-cancel-button @cancel="cancleConfirm" @confirm="confirm">
      <van-notice-bar
          v-if="noticeShow"
          :scrollable="false"
          :text="des"
      />
      <div style="padding: 10px 0px 16px;">
        <div class="confirm-item" v-for="(obj, key) in confirmForm" v-bind:key="key">
          {{(key+1) +'.' + obj.name}}
        </div>
      </div>
    </van-dialog>
    <van-popup v-model="signShow" position="bottom" :style="{ height: '100%' }" :closeable="true" get-container="#app" >
      <signature  ref="signature" @getSign="getSign" :signer="signer"  :id="canvasId"></signature>
    </van-popup>
    <van-popup v-model="successPop" position="right" :style="{ height: '100%',width:'100%' }">
       <div >
        <div class="model-title">
          <van-icon name="arrow-left" size="28" />
        </div>
         <div class="success-icon">
           <van-icon name="passed" color="#00E68F" size="40" />
         </div>
        <div class="success-content" v-if="temp">
          暂存成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
        </div>
        <div class="success-content" v-else>提交成功，{{seconds}}s<a href="javascript:void(0)" @click="goToMap">返回主页</a>
<!--          ,查看<a href="javascript:void(0)" @click="viewDetail">检查详情</a>-->
        </div>
        </div>
       </van-popup>
  </div>
</template>

<script>

import signature from "./signature";
import {
  Toast,Button,Divider, Col, Row,Uploader ,Image,Icon,RadioGroup, Radio,Form,Field,
  Notify,Dialog,NoticeBar,Popup,Checkbox, CheckboxGroup
} from 'vant';
import Compressor from 'compressorjs';
export default {
  components:{signature,
    [Toast.name]: Toast,
    [Button.name]: Button,
    [Uploader.name]: Uploader  ,
    [Icon.name]: Icon ,
    [Form.name]: Form,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Image.name]: Image ,
    [Notify.name]: Notify,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Dialog.name]: Dialog,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [Dialog.Component.name]: Dialog.Component,
    [Col.name]: Col,
    [Row.name]: Row,
    [Divider.name]: Divider
  },
  props:{
    venue:{
      type:Object,
      default: ()=>{
        return {
          name:"场馆1",
          id:"1"
        }
      }
    },
    table:{
      type:Object,
      default: ()=>{
        return {
          table:{},
          tempData:{}
        }
      }
    },
    checkBegin:{
      default: ()=>{
        return new Date().getTime()
      }
    },
    userInfo:{
      type:Object,
      default: ()=>{
        return {
          openId:localStorage.getItem("wljptuser")
        }
      }
    }
  },
  watch: {
    /*监听变化*/
    venue: {
      handler(n, o) {
        this.venueData.infoId = n.id;
        this.venueData.status = -1;
        this.venueData.checkType = n.checkType;
        this.pics = [];
        this.successPop = false;
        this.seconds = 5;
        this.checkedTime = new Date().getTime();
      },
      deep: true,
      immediate:true
    },
    table: {
      handler(n, o) {
        console.log(n,"load to zxjc")
        this.resolveTable();
      },
      deep: true,
      immediate: true
    },

  },
  data(){
    return {
      checkedTime:0,
      checkTimeTimer:null,
      currentPos:[],
      signShow:false,
      tempShow:false,
      labelWidth:window.innerWidth - 165,
      venueData:{
        remark: GlobalConfig.dev?GlobalConfig.userName:localStorage.getItem("wljptuserName"),
        rectify:"",
        type:"",
        infoId:this.venue.id,
        checkerSign:"",
        checkedSign:"",
        status:-1,
        person:GlobalConfig.dev?GlobalConfig.openid:localStorage.getItem("wljptuser")
      },
      pics:[],
      fileList:{},
      groupForm:{},
      formData:{
        // "0001": null,
        // "0002": null,
        // "0003": null,
        // "0004": null,
        // "0005": null,
        // "0006": null,
        // "0007": null,
        // "0008": null,
        // "0009": null,
        // "0010": null,
        // "0011": null,
        // "0012": null,
        // "0013": null,
        // "0014": null,
        // "0015": null,
        // "0016": null,
        // "0017": null,
        "check":{
          value:undefined,
          des:""
        }
      },
      canvasId:"aqscSign",
      uploader:[],
      loading:false,
      noticeShow:false,
      show:false,
      successPop:false,
      checkerSigned:false,
      checkedSigned:false,
      temp:true,
      des:"",
      confirmForm:{},
      seconds:5,
      checkData:{},
      timer:null,
      timeOuter:null,
      tempTime:"",
      tempshow:false,
      fileTest:[],
      signer:"",
      loadDict:true,
      firstFormName:"",
      recordFileJson:{},
      options:{},
      needPics:GlobalConfig.needPhotos,
      noPics:GlobalConfig.notNeeded,
      labelW:10,
      labelW2:window.innerWidth - 175,
    }
  },
  methods:{
    resolveTable(){
      console.log(this.table,"this.formData",this.formData)
      this.getDict(this.table.table);

      if(this.table.tempData){
        this.initData(this.table.tempData)
      }
    },
    // 获取检查项
    getDict(table){
      this.options = {};
      this.venueData.type = table.id;
      let items = table.typeList;
      let group = {};
      items.map((item,index)=>{
        if(!item.groupName){
          item.groupName = "其他";
        }
        if(!group[item.groupName]){
          group[item.groupName] = [];
        }
        group[item.groupName].push(item);
        if(index==0){
          this.firstFormName = item.id;
        }
        if(item.type=='radio1'||item.type=='checkbox'){
          this.options[item.id] = item.options?item.options.split(";"):[];
        }
      })
      this.formData = {};
      for(let k in group){
          group[k].map((item,index)=>{
            if(!this.formData[item.id]){
              this.$set(this.formData,item.id,{
                value:null,
                des:"",
                groupName:k,
                name:item.name,
                type:item.type
              })
              this.$set(this.fileList,item.id,[])
            }
          })
      }
      this.$set(this.formData,"check",{
        value:null,
        des:""
      })
      console.log(group,"group",this.formData,this.fileList)
      this.groupForm = group;
    },
    resetFormData(){
      console.log(this.formData,"initForm")
      this.tempshow = false;
      this.recordFileJson = [];
      for(let k in this.formData){
        this.formData[k] = {
          value:this.formData[k].type=='checkbox'?[]:null,
          name:this.formData[k].name,
          groupName:this.formData[k].groupName,
          des:"",
          type:this.formData[k].type
        };
        this.fileList[k] = [];
      }
    },
    onSubmit(values){
      let rectify = [];
      let confirmForm = [];
      let problemList = [];
      for(let k in values){
        let noPro = Array.isArray(values[k]) && values[k].length==1 && values[k][0]=='没有问题';
        if(values[k]=="0" || (Array.isArray(values[k]) && Number(k)>0 && !noPro)){
          console.log(values[k],k,this.fileList[k]);
          let fileList = [];
          this.formData[k].fileList =this.fileList[k].map(p=>p.url).join(",");
          problemList.push({
            typeId:k,
            checkResult: this.formData[k].des,
            // fileList:JSON.stringify(fileList)
            fileList:fileList
          })
          rectify.push(k);
          if(this.formData[k]){
            console.log(this.formData[k],"k")
            confirmForm.push({
              ...this.formData[k],
              name:this.formData[k].name
            })
          }
        }
      }

      if(rectify.length==0){
        this.venueData.status = 0;
      }else{
        this.venueData.status = 1;
      }
      this.venueData.rectify = rectify.join(",");
      this.venueData.content = JSON.stringify(this.formData);
      this.venueData.problemList = problemList;
      this.confirmForm = confirmForm;
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(this.showPosition);
      }
      let d = this.getDistance(this.venue.lngLat[0],this.venue.lngLat[1],this.currentPos[0],this.currentPos[1]);
      console.log(d,this.venue.lngLat,this.currentPos)
      if(d>(GlobalConfig.nearDistance+0.1)){
        Notify({ type: 'warning', message: "不在检查场所范围内，不能提交！" });
        return false;
      }

      let nowTime = new Date().getTime();
      let timeGap = (nowTime - this.checkBegin)/1000;
      console.log(timeGap,this.checkedTime,this.checkBegin,"检查时间")
      if(timeGap<GlobalConfig.checkTime){
        Notify({ type: 'warning', message: '检查时间少于'+GlobalConfig.checkTime/60 + "分钟，不能提交！" });
        return false;
      }
      if(rectify.length>0){
        this.noticeShow = true;
        this.des = "有" + rectify.length + "项需整改";
      }else{
        this.noticeShow = false
      }

      this.loading = true;
      this.show = true;
    },
    showPosition(position){
      this.currentPos = this.wgs84togcj02(position.coords.longitude,position.coords.latitude);
    },
    wgs84togcj02(lng, lat) {
      let x_PI = 3.14159265358979324 * 3000.0 / 180.0;
      let a = 6378245.0;
      let ee = 0.00669342162296594323;
      let PI = Math.PI;
      lat = +lat;
      lng = +lng;
      if (this.out_of_china(lng, lat)) {
        return [lng, lat]
      } else {
        var dlat = this.transformlat(lng - 105.0, lat - 35.0);
        var dlng = this.transformlng(lng - 105.0, lat - 35.0);
        var radlat = lat / 180.0 * PI;
        var magic = Math.sin(radlat);
        magic = 1 - ee * magic * magic;
        var sqrtmagic = Math.sqrt(magic);
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * PI);
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * PI);
        var mglat = lat + dlat;
        var mglng = lng + dlng;
        return [mglng, mglat]
      }
    },
    transformlat(lng, lat) {
      let PI = Math.PI;
      lat = +lat;
      lng = +lng;
      var ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(lat * PI) + 40.0 * Math.sin(lat / 3.0 * PI)) * 2.0 / 3.0;
      ret += (160.0 * Math.sin(lat / 12.0 * PI) + 320 * Math.sin(lat * PI / 30.0)) * 2.0 / 3.0;
      return ret
    },

    transformlng(lng, lat) {
      let PI = Math.PI;
      lat = +lat;
      lng = +lng;
      var ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
      ret += (20.0 * Math.sin(6.0 * lng * PI) + 20.0 * Math.sin(2.0 * lng * PI)) * 2.0 / 3.0;
      ret += (20.0 * Math.sin(lng * PI) + 40.0 * Math.sin(lng / 3.0 * PI)) * 2.0 / 3.0;
      ret += (150.0 * Math.sin(lng / 12.0 * PI) + 300.0 * Math.sin(lng / 30.0 * PI)) * 2.0 / 3.0;
      return ret
    },

    /**
     * 判断是否在国内，不在国内则不做偏移
     * @param lng
     * @param lat
     * @returns {boolean}
     */
    out_of_china(lng, lat) {
      lat = +lat;
      lng = +lng;
      // 纬度3.86~53.55,经度73.66~135.05
      return !(lng > 73.66 && lng < 135.05 && lat > 3.86 && lat < 53.55);
    },
    getDistance( lat1,  lng1,  lat2,  lng2){
      var radLat1 = lat1*Math.PI / 180.0;
      var radLat2 = lat2*Math.PI / 180.0;
      var a = radLat1 - radLat2;
      var  b = lng1*Math.PI / 180.0 - lng2*Math.PI / 180.0;
      var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a/2),2) +
          Math.cos(radLat1)*Math.cos(radLat2)*Math.pow(Math.sin(b/2),2)));
      s = s *6378.137 ;// EARTH_RADIUS;
      s = Math.round(s * 10000) / 10000;
      return s;
    },
    validator(val,rule){
      if(this.formData[rule.ruleId]["value"]=="0"){
        return val.trim()?true:false;
      }
      return true;
    },
    changeCheck(value,id){

      console.log(this.formData[value]['value'],this.formData[value]['value'].length,this.formData[value]['value'][0].trim()=='没有问题',"lksjskl");
    },
    validator1(val,rule){
      console.log(this.formData[rule.ruleId]["value"],val,"validator1")
      if(!(this.formData[rule.ruleId]['value']&&this.formData[rule.ruleId]['value'].length==1&&this.formData[rule.ruleId]['value'][0].trim()=='没有问题')){
        return val.trim()?true:false;
      }
      return true;
    },
    validatorPic(val,rule){
        if(this.formData[rule.ruleId]["value"]=="0" ){
          return (Array.isArray(val)&&val.length>0)?true:false;
        }else{
          return true;
        }
    },
    validatorPic1(val,rule){
      if(!(this.formData[rule.ruleId]['value']&&this.formData[rule.ruleId]['value'].length==1&&this.formData[rule.ruleId]['value'][0].trim()=='没有问题')){
        return (Array.isArray(val)&&val.length>0)?true:false;
      }else{
        return true;
      }
    },
    save(values){
      this.venueData.checkType = this.venue.checkType;
      let _this = this;
      this.show = true;
      this.temp = false;
      this.venueData.person = localStorage.getItem("wljptuser");
      if(this.pics.length>0){
        this.venueData.picture = this.pics.map(item=>{
          return item.url
        }).join(",");
      }
      let data = this.venueData;
      if(localStorage.getItem("wljptofficeIds")){
        data.officeIds = localStorage.getItem("wljptofficeIds");
      }
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:data
      }).then(res=>{
          if(res.data.success){
            _this.saveLastCheck({
              ...this.userInfo,
              latestCheckStatus:'0'
            })
            _this.checkData = res.data.data;
            this.successPop = true;
            this.checkedTime = new Date().getTime();
            this.seconds = 5;
            _this.timer = setInterval(() => {
              _this.seconds--;
              if(_this.seconds<0){
                _this.successPop = false;
                window.clearInterval(_this.timer);
              }
            }, 1000);
            _this.timeOuter = setTimeout(function(){
              _this.resetFormData();
              _this.$emit("closeReport")
            },5000);

          }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{
         this.loading = false;
      })
    },
    saveLastCheck(data){
      this.$http({
        url: "/zuul/cultural/user" ,
        method: "post",
        data:data
      }).then(res=>{
        if (res.data.success) {
          console.log("更新企业检查状态成功");
        }
      }).catch(e=>{

      }).finally(e=>{
      })
    },
    failed(rule){
      console.log(rule,"errorRule")
      if(rule.errors && rule.errors.length>0){
        this.$refs.checkForm.scrollToField(rule.errors[0].name)
      }
    },
    cancleConfirm(){
      this.show = false;
      this.loading = false;
    },
    confirm(){
      this.save();
    },
    initData(temp){
      this.resetFormData();
      this.$refs.checkForm.resetValidation();
      this.$refs.checkForm.scrollToField(this.firstFormName);
      this.tempTime = "";

      this.pics = [];
      this.loading = false;
      this.show = false;
      this.tempShow = false;
      this.successPop = false;

      if(temp.createTime){
        this.tempTime = "暂存时间："+ temp.createTime.substr(0,16);
        this.tempShow = true;
      }else{
        this.tempTime = '';
        this.tempShow = false;
      }
      if(temp.checkedSign){
        this.venueData.checkedSign = temp.checkedSign;
        this.checkedSigned = true;
      }else{
        this.venueData.checkedSign = "";
        this.checkedSigned = false;
      }
      if(temp.checkerSign){
        this.venueData.checkerSign = temp.checkerSign;
        this.checkerSigned = true;
      }else{
        this.venueData.checkerSign = "";
        this.checkerSigned = false;
      }
      let proFiles = {};
      if(temp.content){
        let json = JSON.parse(temp.content);
        for(let k in json){
          if(this.formData[k]){
            this.formData[k] = {
              ...json[k],
              type:this.formData[k]["type"],
              name:this.formData[k]['name'],
            };
            if(json[k].fileList){
              if(!proFiles[k]){
                proFiles[k] = [];
              }
              proFiles[k] = json[k].fileList.split(",").map(url=>{
                return {
                  url:url,
                  isImage: true
                }
              });
            }

          }
        }

      }
      this.venueData.id = temp.id;
      if(temp.problemList && temp.problemList.length>0){
        temp.problemList.map(item=>{
          let arr = [];
          if(item.recordFileList){
            if(!this.recordFileJson[item.typeId]){
              this.recordFileJson[item.typeId]= item.recordFileList;
            }
            item.recordFileList.map(pic=>{
              arr.push({
                url:pic,
                isImage: true
              })
            })
          }
          // this.fileList[item.typeId] =arr;
          if(proFiles[item.typeId]){
            this.fileList[item.typeId] =arr.concat(proFiles[item.typeId]);
          }else{
            this.fileList[item.typeId] =arr;
          }
        })
      }
      if(temp.picture){
        let p = temp.picture.split(",");
        p.map(item=>{
          this.pics.push({
            url:item,
            isImage:true
          })
        })
      }
      console.log(temp,"temp")
    },
    viewDetail(){
      this.$emit("viewDetail",{
        venue:this.venue,
        check:this.checkData
      })
    },
    getBase64(url, callback) {
      let Img = new Image(),
          dataURL = '';
      Img.src = url + '?v=' + Math.random();
      console.log(url + '?v=' + Math.random())
      Img.setAttribute('crossOrigin', 'Anonymous');
      Img.onload = function() {
      let  canvas = document.createElement('canvas'),
          width = Img.width,
          height = Img.height;
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
      dataURL = canvas.toDataURL('image/jpeg');
        return callback ? callback(dataURL) : null;
      };
    },
    tempSave(){
      let _this = this;
      this.$refs.checkForm.resetValidation();
      let problemList = [];
      for(let k in this.formData){
        if(this.formData[k]["value"]=="0"){
          let fileList = [];
          this.formData[k].fileList =this.fileList[k].map(p=>p.url).join(",");
             /* this.fileList[k].map((pic,index)=>{
                console.log(pic,"pic")
                if(pic.file){
                  fileList.push({
                    name:pic.file.name.replace(/image/,new Date().getTime() + k + "-"+  index ),
                    content:pic.content
                  })
                }
             })*/
          problemList.push({
            typeId:k,
            checkResult: this.formData[k].des,
            fileList:fileList,
            recordFileList:this.recordFileJson[k]
          })
        }
      }
      this.venueData.content = JSON.stringify(this.formData);
      this.venueData.problemList = problemList;
      this.venueData.status = -1;
      if(this.pics.length>0){
        this.venueData.picture = this.pics.map(item=>{
          return item.url
        }).join(",");
      }
      this.$http({
        url: "/zuul/cultural/check/",
        method: "post",
        data:this.venueData
      }).then(res=>{
        if(res.data.success){
          this.successPop = true;
          this.temp = true;
          this.seconds = 5;
          _this.timer = setInterval(() => {
            _this.seconds--;
            if(_this.seconds<0){
              _this.successPop = false;
              window.clearInterval(_this.timer);
            }
          }, 1000);
          _this.timeOuter = setTimeout(function(){
            _this.resetFormData();
            _this.$emit("closeReport")
          },5000);

        }
      }).catch(error=>{
        Notify({ type: 'warning', message: '网络问题，请稍后再试' });
      }).finally(res=>{

      })
    },
    goToMap(){
      this.successPop = false;
      clearTimeout(this.timeOuter);
      this.resetFormData();
      this.$emit("closeReport")
    },
    beforeRead(file,defaultQuqlity=0.6) {
      let fileSize = file.size/1024/1024;
      let quality = defaultQuqlity;
      if(fileSize<0.5){
        quality = 1;
      }else if(fileSize<1){
        quality = 0.8
      }else if(fileSize<2){
        quality = 0.6
      }else if(fileSize<4){
        quality = 0.3   // 0.4 0.24
      }else if(fileSize<6){
        quality = 0.15  // 0.1 0.06   0.2-0.17
      }else if(fileSize<8.1){
        quality = 0.1
      }else{
        return new Promise((resolve,reject)=>{
          Toast('文件大小不能超过 8M');
          reject({
            msg:"图片不能超过6M"
          })

        })
      }

      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          quality: quality,
          success(result){
            resolve(result);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
    del(file){
      if(file.url){
        let url = file.url.replace('/cultural/view?name=','/cultural/remove?name=')
        this.$http({
          url: url,
          method: "delete"
        }).then(res=>{
          if(res.data.success){
            console.log("删除成功")
          }
        })
      }
    },
    afterRead(file,rule){
      let key = rule.name.split('-')[1];
      console.log(key,this.formData[key])
      console.log(this.fileList[key],"上传文件");
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name,file.file);
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{
        if(res.data.success){
          let len = this.fileList[key].length;
          this.fileList[key][len>0?(len - 1):0].url = '/zuul' +res.data.data[0].viewUrl;
          console.log(this.fileList[key],"fileList")
        }
      })
    },
    sendFile(file){
      console.log(file.file)
      let formData = new FormData();
      formData.append(new Date().getTime() + file.file.name,file.file);
     /* formData.append("timed",true);
      formData.append("filename",new Date().getTime() + file.file.name);*/
      this.$http({
        url: "/zuul/oss/file/cultural/upload",
        method: "post",
        headers: {'Content-Type': "multipart/form-data"},
        data:formData
      }).then(res=>{
        if(res.data.success){
            this.pics[this.pics.length>0?(this.pics.length - 1):0].url = '/zuul' +res.data.data[0].viewUrl;
          }
      })
    },
    openSign(value){
      this.signer = value;
      this.signShow = true;
      this.$nextTick(()=>{
        this.$refs.signature.clearArea();
      })
    },
    getSign(value){
      this.venueData[value.value] = value.content;
      if(value.value=='checkerSign'){
        this.checkerSigned = true;
      }else{
        this.checkedSigned = true;
      }
      this.signShow = false;
    }
  },
  mounted(){
    this.venueData.remark = localStorage.getItem("wljptuserName");
    this.venueData.person = localStorage.getItem("wljptuser");
  },
  beforeDestroy() {
    if(this.timer){
      window.clearInterval(this.timer);
    }
  },
}
</script>

<style scoped lang="less">

  .item-label{
    color: #646566;
    font-size: 14px;
    padding: 8px 12px 0px 24px;
  }

  .form-title{
    color: rgba(69, 90, 100, 0.6);
    line-height: 45px;
    padding: 5px 16px 0px;
    background: #f7f8fa;
  }
  .form-label{
    padding: 10px 12px 0px;
    color: #646566;
  }
  .label-tips{
    position: relative;
    left: calc(-100vw + 152px);
    color: red;
    position: relative;
    top: 3px;
  }
  .item{
    position: relative;

    .label-tips{
      position: absolute;
      left: 11px;
      top: 13px;
    }
  }
  .confirm-item{
    padding: 5px 20px;
    color: #323233;
  }
  .model-title{
    letter-spacing: 2px;
    font-weight: 800;
    text-align: center;
    line-height: 50px;
    position: relative;
    .van-icon{
      position: absolute;
      left: 5px;
      top: 10px;
      font-weight: normal;
      color: #969799;
    }
  }
  .success-icon{
    text-align: center;
    position: relative;
    top: 120px;
  }
  .success-content{
    padding: 150px 20px;
    text-align: center;
  }
  .temp-save-btn{
    display: inline-block;
    box-sizing: border-box;
    width:100% ;
    border: 1px solid #ebedf0;
    height: calc(100% - 2px);
    font-size: 16px;
    text-align: center;
    line-height:50px;
    background: #fff;
    //border-radius: 16px;
  }
  /deep/ .form-image .van-field__error-message{
    top: 60px;
    left:90px;
  }
  /deep/ .van-cell__title{
    padding-left: 8px;
  }
  /deep/ .van-radio-group--horizontal{
    //width: 33%;
  }
  /deep/ .van-field__error-message{
    position: absolute;
    top: 1px;
    left: -23px;
  }
  /deep/ .van-dialog{
    width: calc(100vw - 20px);
  }
  /deep/ .van-dialog__header{
    padding-bottom: 10px;
  }
  /deep/ .van-dialog__footer{
    padding: 10px 0px;
  }

  /deep/ .van-radio--horizontal{
    margin-bottom: 6px;
  }

  /deep/ .van-checkbox--horizontal{
    margin-bottom: 8px;
  }
</style>
